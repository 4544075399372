/**
 * /* For desktop
 *
 * @format
 */

@media only screen and (min-width: 769px) {
  .header {
    width: 100%;
    height: 7.125rem;
    margin: 0;
    background-color: #ffffff;
    box-shadow: 0 0 0.625rem #aaadab;
    display: flex;
  }
}

/*For tabs */
@media only screen and (max-width: 768px) and (min-width: 361px) {
  .header {
    width: 100%;
    height: 3.75rem;
    margin: 0;
    background-color: #ffffff;
    box-shadow: 0 0 0.625rem #aaadab;
    display: flex;
  }
}

/* For mobile */
@media only screen and (max-width: 360px) {
  .header {
    width: 100%;
    height: 2.625rem;
    margin: 0;
    background-color: #ffffff;
    box-shadow: 0 0 0.625rem #aaadab;
    display: flex;
  }
}

/* For mobiles */
@media only screen and (max-width: 360px) {
  .left-side {
    padding-left: 1.66%;
  }
  .right-side {
    margin-left: auto;
    padding-right: 5.28%;
  }
  .left-side-text {
    padding-top: 0.5rem;
  }
  .right-side-text {
    margin-left: auto;
    padding-top: 0.438rem;
  }
}

/* For tabs */
@media only screen and (max-width: 768px) and (min-width: 361px) {
  .left-side {
    padding-left: 0.78%;
  }
  .right-side {
    margin-left: auto;
    padding-right: 5.21%;
  }
  .left-side-text {
    padding-top: 0.688rem;
  }
  .right-side-text {
    margin-left: auto;
    padding-top: 1.188rem;
  }
}

/* For Desktop */
@media only screen and (min-width: 769px) {
  .left-side {
    padding-left: 4.76%;
  }
  .right-side {
    padding-right: 0;
  }
  .left-side-text {
    padding-top: 1.563rem;
  }
  .right-side-text {
    margin-left: auto;
    padding-top: 3.25rem;
    padding-right: 0.6875rem;
  }
}

/* For mobile */
@media only screen and (max-width: 360px) {
  .signup-form-step-1 {
    width: 3.563rem;
    height: 1.75rem;
    margin-top: 0.125rem;
    font-family: "Graphik-Medium";
    font-size: 0.75rem;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.33;
    letter-spacing: normal;
    text-align: center;
    color: #dcddde;
  }
}

/* For tabs */
@media only screen and (max-width: 768px) and (min-width: 361px) {
  .signup-form-step-1 {
    /* width: 3.75rem; */
    height: 1.75rem;
    /* margin: 0.5rem 0 0.188rem 32rem; */
    font-family: "Graphik-Medium";
    font-size: 1rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    color: #dcddde;
  }
}

/* For desktop */
@media only screen and (min-width: 769px) {
  .signup-form-step-1 {
    width: 16rem;
    height: 1.75rem;
    /* margin: 1.688rem 0 1rem 42.563rem; */
    font-family: "Graphik-Medium";
    font-size: 1.125rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    text-align: center;
    color: #dcddde;
  }
}
