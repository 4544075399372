/** @format */

.set_schedule_text {
  margin: 5.313rem 0 0.375rem;
  font-family: "Poppins", sans-serif;
  font-size: 2rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #4a4a4a;
}
.main-container {
  min-height: 60rem;
  margin-bottom: 5rem;
}

.schedule_text11 {
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: normal;
  text-align: center;
  color: #4a4a4a;
}

.schedule_text12 {
  margin-top: -0.7rem;
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: normal;
  text-align: center;
  color: #4a4a4a;
}

.mobile-view {
  display: none;
}

.schedule1_label {
  margin-top: 1.5rem;
  font-family: "Poppins", sans-serif;
  font-size: 1.125rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.39px;
  text-align: center;
  color: #4a4a4a;
  white-space: nowrap;
}

.schedule_default_label {
  margin-top: 1.5rem;
  font-family: "Poppins", sans-serif;
  font-size: 1.125rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.39px;
  text-align: center;
  color: #4a4a4a;
  margin-left: 2.5rem;
}

.time_zone_text {
  white-space: nowrap;
  margin-left: -0.5rem;
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 3;
  letter-spacing: -0.75px;
  text-align: right;
  color: #4a4a4a;
}

.days_text {
  white-space: nowrap;
  margin-left: -0.5rem;
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 3;
  letter-spacing: -0.75px;
  text-align: right;
  color: #4a4a4a;
}

.time-scheduled {
  width: 5.5rem;
  height: 2.75rem;
  margin-right: 0.813rem;
  font-family: "Poppins", sans-serif;
  font-size: 1.5rem;
  letter-spacing: -0.94px;
  text-align: center;
  color: #4a90e2;
  padding: 0 0.5rem;
  border-radius: 4px;
  border: solid 1px #979797;
  background-color: #fff !important;
  outline-color: #979797;
}

.time-scheduled-separtor {
  font-family: "Poppins", sans-serif;
  font-size: 1.5rem;
  text-align: center;
  color: #4a90e2;
  border: none !important;
  margin-bottom: 0rem;
}

.time-scheduled-hr {
  padding: 0;
  width: 2rem;
  font-family: "Poppins", sans-serif;
  font-size: 1.5rem;
  letter-spacing: -0.94px;
  text-align: center;
  color: #4a90e2;
  border: none !important;
  margin-top: 0.2rem;
  margin-bottom: 0rem;
}

.time-scheduled-min {
  padding: 0;
  width: 2rem;
  font-family: "Poppins", sans-serif;
  font-size: 1.5rem;
  letter-spacing: -0.94px;
  text-align: center;
  color: #4a90e2;
  border: none !important;
  margin-bottom: 0rem;
  margin-top: 0.2rem;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  background: none;
  display: none;
}

.time-zone-div {
  display: flex;
}

.am-button {
  padding: 0;
  margin-top: 0.25rem;
  margin-right: 0.813rem;
  width: 3rem;
  height: 2.25rem;
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
  color: #4a90e2;
  border-radius: 4px;
  border: solid 1px #4a90e2;
  background-color: #fff !important;
}

.pm-button {
  padding: 0;
  border-width: 1px;
  -webkit-appearance: none !important;
  margin-top: 0.25rem;
  margin-right: 0.813rem;
  width: 3rem;
  height: 2.25rem;
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
  color: #9b9b9b;
}

.standard-time-zone {
  margin-left: -1rem;
  width: 9.438rem;
  height: 2.75rem;
  border-radius: 4px;
  border: solid 1px #979797;
  background-color: #fff !important;
  font-family: "Open Sans", sans-serif;
  font-size: 1rem;
  font-weight: 300;
  font-style: normal;
  text-align: center;
  color: #4a4a4a;
}

.time-zone-section {
  margin-left: -1rem;
  width: 9.438rem;
  height: 2.75rem;
  border-radius: 4px;
  border: solid 1px #979797;
  background-color: #fff !important;
  font-family: "Open Sans", sans-serif;
  font-size: 1rem;
  font-weight: 300;
  font-style: normal;
  text-align: center;
  color: #4a4a4a;
  border-left-color: transparent;
}

/*For Mobiles */
@media only screen and (max-width: 361px) {
  .time_zone_text {
    font-size: 0.875rem;
  }
}

/*For Mobiles */
@media only screen and (max-width: 361px) {
  .schedule1_label {
    font-size: 1rem;
  }
}

/*For Mobiles*/
@media only screen and (max-width: 361px) {
  .set_schedule_text {
    margin: 1.938rem 3.625rem 0.063rem 3.688rem;
    font-size: 1.25rem;
    font-weight: 600;
  }
}

/*For Tabs and Mobiles*/
@media screen and (max-width: 1201px) {
  .schedule_default_label {
    margin-left: 0.5rem;
  }
}

/*For Tabs and Mobiles*/
@media screen and (max-width: 991px) {
  .schedule_text11 {
    max-width: 17.688rem;
    font-size: 0.875rem;
    font-weight: 300;
  }
  .schedule_text12 {
    font-size: 0.875rem;
    font-weight: 300;
  }
  .desktop-view {
    display: none;
  }
  /* .mobile-view {
    display: flex;
    justify-content: center;
  } */
  .standard-time-zone {
    width: 14.438rem;
    margin-left: -2.5rem !important;
  }

  .time-zone-section {
    width: 14.438rem;
    margin-left: -2.5rem !important;
  }

  .schedule_default_label {
    margin-left: -1rem;
  }
}

@media screen and (max-width: 991px) {
  .error-text-div {
    width: 100% !important;
    text-align: center !important;
    height: 1rem !important;
    margin-left: 0rem !important;
  }

  .mobile-view {
    display: flex;
    justify-content: center;
  }

  .edit-schedule-text {
    margin-top: 0.3rem !important;
  }

  .scheduled-timezone {
    margin-left: 1rem !important;
    margin-bottom: -0.5rem !important;
  }

  .delete-button-alignment {
    text-align: right !important;
  }

  .days-selected-div {
    margin-bottom: 0rem !important;
  }
}

/*For Tabs and Mobiles*/
@media screen and (max-width: 767px) {
  .standard-time-zone {
    width: 13.75rem;
    margin-left: 0rem !important;
  }

  .time-zone-section {
    width: 13.75rem;
    margin-left: 0rem !important;
  }

  .time_zone_text {
    text-align: center;
  }

  .time-zone-div {
    justify-content: center;
    margin-top: -1rem;
  }

  .center-aligned-timezone {
    display: flex;
    justify-content: center;
    text-align: center;
    margin-top: 0.8rem;
  }

  .days_text {
    text-align: center;
    margin-bottom: 0rem;
  }

  .error-text-div {
    margin-left: 0rem !important;
    margin-top: 0.5rem;
    margin-bottom: 1.5rem !important;
  }

  .center-aligned-div {
    text-align: center;
  }

  .add-schedule-button {
    height: 2.25rem !important;
  }

  .add-schedule-button-disabled {
    height: 2.25rem !important;
  }

  .save-button {
    width: 13.75rem !important;
    height: 2.25rem !important;
  }

  .save-button-disabled {
    width: 13.75rem !important;
    height: 2.25rem !important;
  }

  .padding {
    padding-left: 0rem !important;
    padding-right: 0rem !important;
  }

  .mobile-view {
    display: flex;
    justify-content: center;
  }

  .edit-schedule-text {
    margin-top: 0.3rem !important;
  }
  .scheduled-timezone {
    margin-left: 0rem !important;
    margin-bottom: -0.5rem !important;
  }

  .days-div {
    display: block;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .buttons-view {
    display: flex;
    justify-content: center;
  }

  .display-delete {
    margin-left: 2rem !important;
    justify-content: center !important;
  }

  .week-day-button-unselected {
    width: 2rem !important;
    height: 2rem !important;
    font-size: 1rem !important;
  }

  .week-day-button-selected {
    width: 2rem !important;
    height: 2rem !important;
    font-size: 1rem !important;
  }

  .week-day-button-selected-error {
    width: 2rem !important;
    height: 2rem !important;
    font-size: 1rem !important;
  }

  .display-div {
    justify-content: center;
    margin-left: 0rem;
    margin-top: 1rem;
    margin-bottom: -3rem;
  }

  .delete-schedule-text {
    margin-right: 20px;
  }
}

@media screen and (max-width: 400px) {
  .days-div {
    margin-left: 0rem;
    display: flex;
    justify-content: center;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

/*For Tabs and Mobiles*/
@media screen and (max-width: 991px) {
  .upgrade-main-div {
    border: 1px solid black;
    width: 15.75rem;
    height: 9.75rem;
    margin-top: 3rem;
    padding-left: 0.5rem;
  }

  .upgrade-main-error-div {
    border: 1px solid red;
    width: 15.75rem;
    height: 9.75rem;
    margin-top: 3rem;
    padding-left: 0.5rem;
  }
}

/*For Mobiles */
@media only screen and (min-width: 991px) {
  .standard-time-zone {
    width: 14.438rem;
    margin-left: -2.5rem !important;
  }

  .time-zone-section {
    width: 14.438rem;
    margin-left: -2.5rem !important;
  }
}

@media only screen and (min-width: 768px) {
  .display-delete {
    margin-left: 3.5rem !important;
  }
}

@media only screen and (min-width: 1200px) {
  .standard-time-zone {
    width: 14.438rem;
    margin-left: 0rem !important;
  }

  .time-zone-section {
    width: 14.438rem;
    margin-left: 0rem !important;
  }

  .display-div {
    display: flex;
    margin-left: -1rem;
  }
}

.week-day-button-unselected {
  width: 2.5rem;
  height: 2.5rem;
  margin: 0 0.5rem 0 0;
  border: solid 1px #9b9b9b !important;
  background-color: #fff !important;
  border-radius: 50%;
  font-size: 1.5rem;
  font-weight: 300;
  text-align: center;
  color: #9b9b9b;
  outline-color: #979797 !important;
}

.week-day-button-selected {
  width: 2.5rem;
  height: 2.5rem;
  margin: 0 0.5rem 0 0;
  border: solid 1px transparent !important;
  background-color: #60afff !important;
  border-radius: 50%;
  font-size: 1.5rem;
  font-weight: 300;
  text-align: center;
  color: #fff;
  outline-color: transparent !important;
}

.week-day-button-selected-error {
  width: 2.5rem;
  height: 2.5rem;
  margin: 0 0.5rem 0 0;
  border: solid 1px red !important;
  background-color: white !important;
  border-radius: 50%;
  font-size: 1.5rem;
  font-weight: 300;
  text-align: center;
  color: red;
}

/*For Mobiles */
@media only screen and (min-width: 992px) {
  .week-day-button-unselected {
    width: 2.75rem;
    height: 2.75rem;
    margin: 0 1rem 0 0;
  }

  .week-day-button-selected {
    width: 2.75rem;
    height: 2.75rem;
    margin: 0 1rem 0 0;
  }

  .week-day-button-selected-error {
    width: 2.75rem;
    height: 2.75rem;
    margin: 0 1rem 0 0;
  }

  .display-div {
    display: flex;
    margin-left: 1rem;
  }
}

.margin {
  margin-top: 1.5rem;
}

.days-margin {
  margin-top: 1rem;
}

.delete-schedule-text {
  /* width: 8rem; */
  font-family: "Open Sans", sans-serif;
  font-size: 0.875rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.05px;
  text-align: center;
  color: #007ee5;
  text-decoration: underline;
  background-color: white !important;
  border-color: white;
  border-width: 0;
  padding: 0;
  margin-right: 35px;
}

.update-schedule-text {
  /* width: 8rem; */
  font-family: "Open Sans", sans-serif;
  font-size: 0.875rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.05px;
  text-align: center;
  color: #007ee5;
  text-decoration: underline;
  background-color: white !important;
  border-color: white;
  border-width: 0;
  padding: 0;
}

.save-button {
  width: 13.75rem;
  height: 3rem;
  margin-right: 20px;
  border-radius: 4px;
  border: solid 1px #2544da;
  background-color: #2544da !important;
  font-family: "Poppins", sans-serif;
  font-size: 1.125rem;
  font-weight: 500;
  letter-spacing: -0.5px;
  text-align: center;
  color: #fff;
}

.save-button-disabled {
  width: 13.75rem;
  height: 3rem;
  margin-right: 20px;
  border-radius: 4px;
  border: solid 1px #dcddde;
  background-color: #dcddde !important;
  font-family: "Poppins", sans-serif;
  font-size: 1.125rem;
  font-weight: 500;
  letter-spacing: -0.5px;
  text-align: center;
  color: #fff;
}

.save-button:hover {
  background-color: #192f97 !important;
}

.save-button-disabled:hover {
  border: solid 1px #dcddde !important;
  color: white;
  background-color: #dcddde !important;
}

.add-schedule-button {
  height: 3rem;
  border-radius: 4px;
  border: solid 1px #2544da;
  background-color: white !important;
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  font-weight: 500;
  text-align: center;
  color: #2544da;
}

.add-schedule-button-disabled {
  height: 3rem;
  border-radius: 4px;
  border: solid 1px #dcddde;
  background-color: #dcddde !important;
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  font-weight: 500;
  text-align: center;
  color: white;
}

.add-schedule-button:hover {
  border: solid 1px #2544da !important;
  color: white;
  background-color: #2544da !important;
}

.add-schedule-button-disabled:hover {
  border: solid 1px #dcddde !important;
  color: white;
  background-color: #dcddde !important;
}

.separation-line {
  margin-top: 1.5rem !important;
  width: 0.125rem;
  height: 11.438rem;
  margin: 0 0.938rem 0 0;
  opacity: 0.6;
  border: solid 0.5px #9b9b9b;
}

/* For mobile */
@media only screen and (max-width: 360px) {
  .info-icon {
    width: 1.25rem;
    height: 1.25rem;
  }
}

@media only screen and (min-width: 361px) and (max-width: 815px) {
  .info-icon {
    display: none;
  }
}

@media only screen and (min-width: 815px) {
  .info-icon {
    width: 1.25rem;
    height: 1.25rem;
  }
}

.tooltip-text {
  visibility: hidden;
  width: 12.5rem;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 0.375rem;
  padding: 0.625rem 0.9375rem;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

/* For mobile */
@media only screen and (max-width: 360px) {
  .icon-container {
    display: none;
  }
}

@media only screen and (min-width: 361px) and (max-width: 450px) {
  .icon-container {
    padding-top: 0.5rem;
    padding-left: 0.5rem;
  }
}

@media only screen and (min-width: 450px) {
  .icon-container {
    padding-top: 0.5rem;
    padding-left: 0.9375rem;
  }
}

.icon-container:hover .tooltip-text {
  visibility: visible;
}

.schedule-div {
  display: flex;
  margin-left: -1rem;
  flex-direction: column;
  align-items: center;
}

.mid-div {
  display: flex;
  flex-direction: column;
}

.scheduled-time {
  font-size: 1.375rem;
  line-height: 1.25;
  align-items: center;
  color: #9b9b9b;
}

.scheduled-time-period {
  font-size: 0.875rem;
  line-height: 2.1;
  align-items: center;
  color: #9b9b9b;
}

.scheduled-timezone {
  font-size: 0.875rem;
  line-height: 2.1;
  align-items: center;
  color: #9b9b9b;
  white-space: nowrap;
  margin: 0 0.2rem;
}

.schedule-sub-div {
  display: flex;
  height: 1.375rem;
}

.edit-schedule-text {
  margin-top: 0.2rem;
  margin-left: 0.7rem;
  font-family: "Open Sans", sans-serif;
  font-size: 0.875rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.05px;
  text-align: center;
  color: #007ee5;
  text-decoration: underline;
  background-color: white !important;
  border-color: white;
  border-width: 0;
  padding: 0;
}

.hr {
  border: solid 1px #dcddde;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
}

.save-button-div {
  width: 100%;
  text-align: center;
  margin-top: 2rem;
  margin-left: 4rem;
}

.error-text-div {
  width: 100%;
  text-align: center;
  margin-left: 4rem;
  height: 1rem;
  margin-bottom: 0.5rem;
}

.error-text-message {
  font-family: "Open Sans", sans-serif;
  font-size: 0.625rem;
  font-weight: normal;
  color: #d0021b;
  margin-block-start: 0;
  margin-block-end: 0;
}

.center-aligned {
  text-align: center;
}

.optins-style {
  font-size: 1rem;
  color: #d0021b;
}

.row-style {
  margin-left: 0rem !important;
  margin-right: 0rem !important;
}

.time-zone-section {
  width: 231px;
  height: 40px;
  border-radius: 4px;
  border: solid 1px #979797;
  border-left-color: transparent;
  background-color: var(--white);
}

.plain-div {
  width: 230px;
  height: 38.91px;
  border-radius: 4px;
  border: solid 1px #979797;
  border-top-color: transparent;
  /* border-left-color: transparent; */
  position: absolute;
  font-weight: 300;
  color: #4d4d4d;
  text-align: left;
  font-size: 0.875rem;
  font-family: sofia-pro;
  background-color: white !important;
}

.select-down-icon-style {
  width: 13px;
  height: 10px;
  position: absolute !important;
  margin-left: 11.5rem !important;
  color: #707070 !important;
  margin-top: 0rem !important;
}

.confirm-div {
  width: 231px;
  height: 440px;
  border-radius: 4px;
  border: solid 1px #979797;
  border-top-color: transparent;
  position: absolute;
  font-weight: 300;
  color: #4d4d4d;
  text-align: left;
  font-size: 0.875rem;
  font-family: sofia-pro;
  background-color: white !important;
  z-index: 10;
  position: relative;
  overflow: scroll;
}

.paper {
  border-radius: 20px !important;
  box-shadow: none !important;
}

.menu-list {
  border-radius: 20px !important;
  padding-top: 0rem !important;
  padding-bottom: 0rem !important;
  background-color: transparent !important;
}

.main-menu-item {
  background-color: transparent !important;
  font-size: 14px !important;
  font-weight: 300 !important;
  color: #4a4a4a !important;
  font-family: "Poppins", sans-serif !important;
  margin-top: 0.1rem !important;
  min-height: auto !important;
}

.expandLessIcon {
  position: absolute !important;
  margin-left: 5rem !important;
  color: #707070 !important;
  margin-top: 0rem !important;
}

.menuItem {
  background-color: transparent !important;
  font-size: 0.875rem !important;
  font-weight: 300 !important;
  text-align: left !important;
  color: #4d4d4d !important;
  font-family: sofia-pro !important;
  margin-top: 0.1rem !important;
}

.listMainItem {
  padding: 1rem;
  padding-left: 0rem;
}

.listItem {
  display: flex;
  flex-direction: column;
}

.cursor {
  position: relative;
  display: flex;
  flex-direction: row;
}
.cursor i {
  position: absolute;
  width: 1px;
  height: 80%;
  background-color: gray;
  left: 4.5rem;
  top: 10%;
  animation-name: blink;
  animation-duration: 800ms;
  animation-iteration-count: infinite;
  opacity: 1;
}

.cursor input:focus + i {
  display: none;
}

@keyframes blink {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.display-div {
  display: flex;
  margin-left: 0rem;
}

.display-delete {
  display: flex;
  margin-left: 4rem;
  margin-top: 0.3rem;
  margin-bottom: 0.5rem;
}

.buttons-view {
  display: flex;
  margin-left: 1rem;
  margin-right: 1rem;
}

.days-selected-div {
  display: flex;
  justify-content: center;
  text-align: center;
  margin-bottom: -0.5rem;
}

.time-zone-text {
  margin-block-start: 0em;
  margin-block-end: 0em;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 300;
  color: #4a4a4a;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 169px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.week-day-button {
  padding: 0;
}
