/** @format */

@media only screen and (max-width: 450px) {
  .change-password-title {
    height: 1.75rem;
    padding-top: 0.125rem;
    margin-top: 1.9375rem;
    font-family: "Poppins", sans-serif;
    font-size: 1.25rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: center;
    color: #4a4a4a;
  }

  .change-password-description {
    height: 1.75rem;
    margin-top: 0.125rem;
    font-family: "Poppins", sans-serif;
    font-size: 0.875rem;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: center;
    color: #4a4a4a;
  }

  .password-field-container {
    padding-top: 2.25rem;
  }

  .password-field-cp {
    height: 2.25rem;
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
    border-radius: 2px;
    border: solid 1px #bdbabe;
    background-color: #ffffff;
    padding-left: 0.9375rem;
  }

  .verify-password-field-cp {
    height: 2.25rem;
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
    border-radius: 2px;
    border: solid 1px #bdbabe;
    background-color: #ffffff;
    padding-left: 0.9375rem;
  }

  ::placeholder {
    /* padding-left: 0.8125rem; */
    font-family: "Open Sans", sans-serif;
    font-size: 0.875rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: 0.16px;
    color: #9b9b9b;
  }

  .error-text {
    text-align: center;
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 0.2rem;
    margin-bottom: 0.6125rem;
    height: 0.6875rem;
    font-family: "Open Sans", sans-serif;
    font-size: 0.6875rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #d0021b;
  }

  .backend-error {
    margin-top: 2.125rem;
  }

  .change-password-button {
    width: 58.33%;
    height: 2.25rem;
    margin-left: 20.83%;
    margin-right: 20.83%;
    border-radius: 4px;
    border: solid 1px #2544da;
    background-color: #2544da;
    font-family: "Poppins", sans-serif;
    font-size: 0.875rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.04px;
    text-align: center;
    color: #ffffff;
  }

  button:hover {
    background-color: #192f97;
  }

  .button-container {
    /* margin-top: 2.125rem; */
    margin-bottom: 20.1875rem;
  }

  input {
    outline: none;
  }

  input:focus {
    border: solid 1px #2544da;
  }

  .new-to-seoradar {
    margin-top: 1.5625rem;
    height: 1.5625rem;
    /* margin-bottom: 17.0625rem; */
  }

  .new-to-seoradar-text {
    font-family: "Poppins", sans-serif;
    font-size: 0.875rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #4a4a4a;
  }

  .new-to-seoradar-link {
    margin-left: 0.1875rem;
    font-family: "Poppins", sans-serif;
    font-size: 0.875rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #007ee5;
    text-decoration: none;
    border-bottom: 1px solid #007ee5;
    background-color: white !important;
  }
}

@media only screen and (min-width: 450px) and (max-width: 792px) {
  .change-password-title {
    height: 2.2rem;
    margin-top: 3.5rem;
    font-family: "Poppins", sans-serif;
    font-size: 1.5rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #4a4a4a;
  }

  .change-password-description {
    height: 1.75rem;
    margin-top: 0.25rem;
    font-family: "Poppins", sans-serif;
    font-size: 1rem;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    text-align: center;
    color: #555555;
  }

  .password-field-container {
    padding-top: 2.25rem;
  }

  .password-field-cp {
    height: 2.25rem;
    width: 18.75rem;
    margin-left: calc((100% - 18.75rem) * (1 / 2));
    margin-right: calc((100% - 18.75rem) * (1 / 2));
    border-radius: 0.125rem;
    border: solid 1px #bdbabe;
    background-color: #ffffff;
    padding-left: 0.9375rem;
  }

  .verify-password-field-cp {
    height: 2.25rem;
    width: 18.75rem;
    margin-left: calc((100% - 18.75rem) * (1 / 2));
    margin-right: calc((100% - 18.75rem) * (1 / 2));
    border-radius: 0.125rem;
    border: solid 1px #bdbabe;
    background-color: #ffffff;
    padding-left: 0.9375rem;
  }

  ::placeholder {
    /* padding-left: 0.8125rem; */
    font-family: "Open Sans", sans-serif;
    font-size: 0.875rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: 0.16px;
    color: #9b9b9b;
  }

  .error-text {
    text-align: center;
    margin-left: calc((100% - 18.75rem) * (1 / 2));
    margin-right: calc((100% - 18.75rem) * (1 / 2));
    margin-top: 0.2rem;
    margin-bottom: 0.6125rem;
    height: 0.6875rem;
    font-family: "Open Sans", sans-serif;
    font-size: 0.6875rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #d0021b;
  }

  .backend-error {
    margin-top: 2.3125rem;
  }

  .change-password-button {
    height: 2.5rem;
    width: 15rem;
    margin-left: calc((100% - 15rem) * (1 / 2));
    margin-right: calc((100% - 15rem) * (1 / 2));
    border-radius: 4px;
    border: solid 1px #2544da;
    background-color: #2544da;
    font-family: "Poppins", sans-serif;
    font-size: 1rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
  }

  button:hover {
    background-color: #192f97;
  }

  .button-container {
    /* margin-top: 2.3125rem; */
    margin-bottom: 8.3625rem;
  }

  .new-to-seoradar {
    margin-top: 1.8rem;
    height: 1.5625rem;
    /* margin-bottom: 5rem; */
  }

  input {
    outline: none;
  }

  input:focus {
    border: solid 1px #2544da;
  }

  .new-to-seoradar-text {
    font-family: "Poppins", sans-serif;
    font-size: 0.75rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #4a4a4a;
  }

  .new-to-seoradar-link {
    margin-left: 0.1875rem;
    font-family: "Poppins", sans-serif;
    font-size: 0.875rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #007ee5;
    background-color: white !important;
  }
}

@media only screen and (min-width: 792px) {
  .change-password-title {
    height: 3rem;
    margin-top: 5.375rem;
    font-family: "Poppins", sans-serif;
    font-size: 2rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #4a4a4a;
  }

  .change-password-description {
    height: 1.75rem;
    margin-top: 0.25rem;
    font-family: "Poppins", sans-serif;
    font-size: 1.125rem;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    text-align: center;
    color: #555555;
  }

  .password-field-container {
    padding-top: 2.4375rem;
  }

  .password-field-cp {
    height: 2.25rem;
    width: 25rem;
    margin-left: calc((100% - 25rem) * (1 / 2));
    margin-right: calc((100% - 25rem) * (1 / 2));
    border-radius: 0.125rem;
    border: solid 1px #bdbabe;
    background-color: #ffffff;
    padding-left: 0.9375rem;
  }

  .verify-password-field-cp {
    height: 2.25rem;
    width: 25rem;
    margin-left: calc((100% - 25rem) * (1 / 2));
    margin-right: calc((100% - 25rem) * (1 / 2));
    border-radius: 0.125rem;
    border: solid 1px #bdbabe;
    background-color: #ffffff;
    padding-left: 0.9375rem;
  }

  ::placeholder {
    /* padding-left: 0.8125rem; */
    font-family: "Open Sans", sans-serif;
    font-size: 0.875rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: 0.16px;
    color: #9b9b9b;
  }

  .error-text {
    text-align: center;
    margin-left: calc((100% - 25rem) * (1 / 2));
    margin-right: calc((100% - 25rem) * (1 / 2));
    margin-top: 0.2rem;
    margin-bottom: 0.6125rem;
    height: 0.6875rem;
    font-family: "Open Sans", sans-serif;
    font-size: 0.6875rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #d0021b;
  }

  .backend-error {
    margin-top: 2.3125rem;
  }

  .change-password-button {
    height: 3rem;
    width: 15rem;
    margin-left: calc((100% - 15rem) * (1 / 2));
    margin-right: calc((100% - 15rem) * (1 / 2));
    border-radius: 4px;
    border: solid 1px #2544da;
    background-color: #2544da;
    font-family: "Poppins", sans-serif;
    font-size: 1.125rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
  }

  button:hover {
    background-color: #192f97;
  }

  .button-container {
    /* margin-top: 2.3125rem; */
    margin-bottom: 11.25rem;
  }

  .new-to-seoradar {
    margin-top: 2.1875rem;
    height: 1.5625rem;
    /* margin-bottom: 7.5rem; */
  }

  input {
    outline: none;
  }

  input:focus {
    border: solid 1px #2544da;
  }

  .new-to-seoradar-text {
    font-family: "Poppins", sans-serif;
    font-size: 0.875rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #4a4a4a;
  }

  .new-to-seoradar-link {
    margin-left: 0.1875rem;
    font-family: "Poppins", sans-serif;
    font-size: 0.875rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #007ee5;
    background-color: white !important;
  }
}