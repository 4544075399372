/**
 * /*For Mobiles
 *
 * @format
 */

@media only screen and (max-width: 361px) {
  .logo {
    width: 6.25rem;
    height: 1.688rem;
    /* margin: 0.313rem 11.188rem 0.313rem 0.375rem; */
    object-fit: contain;
  }
}

@media only screen and (min-width: 769px) {
  .logo-image {
    width: 16.813rem;
    height: 4.438rem;
  }
}

/* For Desktop */
@media only screen and (min-width: 769px) {
  .logo {
    width: 16.813rem;
    height: 4.438rem;
    /* margin: 0.313rem 11.188rem 0.313rem 0.375rem; */
    object-fit: contain;
  }
}

/*For tabs */
@media only screen and (max-width: 769px) and (min-width: 361px) {
  .logo {
    width: 9.375rem;
    height: 2.438rem;
    /* margin: 0.313rem 11.188rem 0.313rem 0.375rem; */
    object-fit: contain;
  }
}

.logo-not-loaded {
  width: 16.813rem;
  height: 4.438rem;
  /* margin: 0.313rem 11.188rem 0.313rem 0.375rem; */
  object-fit: contain;
}

.display-none {
  display: none;
}
