/** @format */

@media only screen and (min-width: 493px) {
  .enter-domain-text {
    height: 3rem;
    margin-top: 5.375rem;
    margin-left: auto;
    margin-right: auto;
    font-family: "Poppins", sans-serif;
    font-size: 2rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #4a4a4a;
  }
}

@media only screen and (min-width: 361px) and (max-width: 492px) {
  .enter-domain-text {
    height: 1.75rem;
    margin-top: 3rem;
    margin-left: auto;
    margin-right: auto;
    font-family: "Poppins", sans-serif;
    font-size: 1.5rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: center;
    color: #4a4a4a;
  }
}

@media only screen and (max-width: 360px) {
  .enter-domain-text {
    height: 1.75rem;
    margin-top: 1.9375rem;
    margin-left: auto;
    margin-right: auto;
    font-family: "Poppins", sans-serif;
    font-size: 1.25rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: center;
    color: #4a4a4a;
  }
}

.enter-domain-img-div {
  height: 7.6rem;
}

/* For tablet + desktop */
@media only screen and (min-width: 361px) {
  .www-icon {
    margin-top: 1.625rem;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
}

/* For mobile */
@media only screen and (max-width: 360px) {
  .www-icon {
    margin-top: 2.9375rem;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
}

@media only screen and (min-width: 361px) and (max-width: 450px) {
  .domain-container {
    display: flex;
    margin-top: 2.3125rem;
  }
  .y {
    width: 15rem;
  }
  .x {
    width: calc((100% - 15rem) / 2);
  }
  .z {
    width: calc((100% - 15rem) / 2);
  }
}

@media only screen and (min-width: 450px) and (max-width: 564px) {
  .domain-container {
    display: flex;
    margin-top: 2.3125rem;
  }
  .y {
    width: 17.5rem;
  }
  .x {
    width: calc((100% - 17.5rem) / 2);
  }
  .z {
    width: calc((100% - 17.5rem) / 2);
  }
}

@media only screen and (min-width: 565px) {
  .domain-container {
    display: flex;
    margin-top: 2.3125rem;
  }
  .y {
    width: 400px;
  }
  .x {
    width: calc((100% - 400px) / 2);
  }
  .z {
    width: calc((100% - 400px) / 2);
  }
}

/* For mobile */
@media only screen and (max-width: 360px) {
  .domain-label {
    height: 20px;
    margin-bottom: 0.25rem;
    margin-left: 14.17%;
    margin-top: 1.75rem;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: 0.16px;
    color: #555;
  }
}

@media only screen and (min-width: 361px) and (max-width: 450px) {
  .domain-label {
    font-family: "Poppins", sans-serif;
    font-size: 0.75rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: 0.16px;
    color: #555;
    text-align: right;
    padding-top: 0.75rem;
    padding-right: 0.5rem;
  }
}

/* For tablet + desktop */
@media only screen and (min-width: 450px) {
  .domain-label {
    font-family: "Poppins", sans-serif;
    font-size: 0.875rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: 0.16px;
    color: #555555;
    text-align: right;
    padding-top: 0.75rem;
    padding-right: 0.9375rem;
  }
}

/* For mobile */
@media only screen and (max-width: 360px) {
  .domain-input {
    outline: none;
    width: 100%;
    height: 2.25rem;
    object-fit: contain;
    border-radius: 2px;
    border: solid 1px #bdbabe;
    box-shadow: 0 1px #bdbabe;
  }
}

@media only screen and (min-width: 361px) and (max-width: 450px) {
  .domain-input {
    outline: none;
    width: 15rem;
    height: 2.25rem;
    border-radius: 2px;
    border: solid 1px #bdbabe;
    box-shadow: 0 1px #bdbabe, 0 -1px #bdbabe;
    background-color: #ffffff;
  }
}

@media only screen and (min-width: 450px) and (max-width: 564px) {
  .domain-input {
    outline: none;
    width: 17.5rem;
    height: 2.25rem;
    border-radius: 2px;
    border: solid 1px #bdbabe;
    box-shadow: 0 1px #bdbabe, 0 -1px #bdbabe;
    background-color: #ffffff;
  }
}

@media only screen and (min-width: 565px) {
  .domain-input {
    outline: none;
    width: 25rem;
    height: 2.25rem;
    border-radius: 2px;
    border: solid 1px #bdbabe;
    box-shadow: 0 1px #bdbabe, 0 -1px #bdbabe;
    background-color: #ffffff;
  }
}

/* For mobile */
@media only screen and (max-width: 360px) {
  ::placeholder {
    /* padding-left: 0.75rem; */
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: 0.16px;
    color: #9b9b9b;
  }
}

/* For tablet + desktop */
@media only screen and (min-width: 361px) {
  ::placeholder {
    /* padding-left: 0.75rem; */
    font-family: "Open Sans", sans-serif;
    font-size: 0.875rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: 0.16px;
    color: #9b9b9b;
  }
}

/* For mobile */
@media only screen and (max-width: 360px) {
  .domain-input-parent {
    margin-left: 10%;
    margin-right: 10%;
  }
}

/* For tablet + desktop */
@media only screen and (min-width: 361px) {
  .domain-input-parent {
    margin: auto;
  }
}

/* For mobile */
@media only screen and (max-width: 360px) {
  .monitor-domain-parent {
    padding-left: 21.11%;
    padding-right: 20.55%;
    margin-top: 2.5625rem;
    margin-bottom: 15.125rem;
  }
}

/* For mobile */
@media only screen and (max-width: 360px) {
  .monitor-domain {
    width: 100%;
    height: 2.25rem;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.04px;
    text-align: center;
    color: #ffffff;
    border-radius: 4px;
    border: solid 1px #2544da;
    background-color: #2544da;
  }
}

/* For tablet + desktop */
@media only screen and (min-width: 361px) {
  .monitor-domain {
    width: 15rem;
    height: 3rem;
    margin-top: 2.5625rem;
    margin-bottom: 9.125rem;
    margin-left: auto;
    margin-right: auto;
    display: block;
    font-family: Poppins;
    font-size: 1.125rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    border-width: 0;
    border-radius: 0.25rem;
    border-color: #2544da;
    background-color: #2544da;
    color: #ffffff;
  }
}

/* For mobile */
@media only screen and (max-width: 360px) {
  .invalid-input {
    color: #d0021b;
    font-family: "Open Sans", sans-serif;
    font-size: 0.6875rem;
    margin-top: 0.3125rem;
    padding-left: 1rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }
}

@media only screen and (max-width: 345px) {
  .invalid-input {
    font-size: 0.625rem;
  }
}

@media only screen and (min-width: 361px) and (max-width: 450px) {
  .invalid-input {
    color: #d0021b;
    font-family: "Open Sans", sans-serif;
    font-size: 0.625rem;
    margin-top: 0.3125rem;
    padding-left: 0.2rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }
}

/* For tablet + desktop */
@media only screen and (min-width: 450px) {
  .invalid-input {
    color: #d0021b;
    font-family: "Open Sans", sans-serif;
    font-size: 0.6875rem;
    margin-top: 0.3125rem;
    padding-left: 0.9375rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }
}

/* For mobile */
@media only screen and (max-width: 360px) {
  .info-icon {
    width: 1.25rem;
    height: 1.25rem;
  }
}

@media only screen and (min-width: 361px) and (max-width: 815px) {
  .info-icon {
    display: none;
  }
}

@media only screen and (min-width: 815px) {
  .info-icon {
    width: 1.25rem;
    height: 1.25rem;
  }
}

.tooltip-text {
  visibility: hidden;
  width: 12.5rem;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 0.375rem;
  padding: 0.625rem 0.9375rem;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

/* For mobile */
@media only screen and (max-width: 360px) {
  .icon-container {
    display: none;
  }
}

@media only screen and (min-width: 361px) and (max-width: 450px) {
  .icon-container {
    padding-top: 0.5rem;
    padding-left: 0.5rem;
  }
}

@media only screen and (min-width: 450px) {
  .icon-container {
    padding-top: 0.5rem;
    padding-left: 0.9375rem;
  }
}

.icon-container:hover .tooltip-text {
  visibility: visible;
}

/* For mobile */
@media only screen and (max-width: 360px) {
  .invalid-field {
    border: solid 1px #d0021b;
    box-shadow: 1px 1px #d0021b;
  }
}

/* For tablet + desktop */
@media only screen and (min-width: 361px) {
  .invalid-field {
    border: solid 1px #d0021b;
    box-shadow: 0 -1px #d0021b;
  }
}

.padded-input {
  padding-left: 0.9375rem;
}

.error-text-md {
  text-align: center;
  margin-top: 0.2rem;
  font-family: "Open Sans", sans-serif;
  font-size: 0.6875rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #d0021b;
}

.schedule-for-tech-support {
  color: #007ee5;
  background-color: white;
  border: white;
}

.schedule-for-tech-support:hover {
  background-color: white;
}

.display-none {
  display: none;
}

/* For tablet + desktop */
@media only screen and (min-width: 361px) {
  .www-icon-notloaded {
    margin-top: 1.625rem;
    width: 136px;
    height: 122px;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
}

/* For mobile */
@media only screen and (max-width: 360px) {
  .www-icon-notloaded {
    margin-top: 2.9375rem;
    width: 136px;
    height: 122px;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
}
