/** @format */

.current-plan-info {
  font-family: "Poppins", sans-serif;
  font-size: 0.6875rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.45;
  letter-spacing: normal;
  text-align: center;
  color: #4a4a4a;
  width: 14.125rem;
  padding-left: 0.8125rem;
  padding-right: 0.8125rem;
  padding-top: 1rem;
}
.next-plan {
  margin-top: 0.375rem;
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  text-align: center;
  color: #4a4a4a;
  width: 14.125rem;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.upgrade-link-container {
  margin-top: 0.3125rem;
  font-family: "Poppins", sans-serif;
  font-size: 0.875rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.44px;
  text-align: center;
  width: 14.125rem;
}
.upgrade-link {
  color: #007ee5;
  text-decoration: underline;
  background-color: white;
  border-color: white;
  border-width: 0;
  padding: 0;
}

/*For Tabs and Mobiles*/
@media screen and (max-width: 992px) {
  .upgrade-div {
    margin-top: 0rem !important;
  }

  .upgrade-div-error {
    margin-top: 0rem !important;
    border: none !important;
  }
}

.upgrade-div {
  margin-top: 3rem;
  border: 1px solid white;
}

.upgrade-div-error {
  margin-top: 3rem;
  border: 1px solid red;
}

.upgrade-to-link {
  font-family: "Open Sans", sans-serif;
  font-size: 0.875rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.05px;
  text-align: center;
  color: #007ee5;
  text-decoration: underline;
  background-color: white !important;
  border-color: white;
  border-width: 0;
  padding: 0;
}
