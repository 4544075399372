/** @format */

.main-container {
  width: 100%;
}

/* For Mobiles */
@media only screen and (max-width: 450px) {
  .main-container {
    max-height: 41.125rem;
  }
}

/* For Desktop */
.top-margin {
  margin-top: 5.375rem;
}
/*For tabs */
@media only screen and (max-width: 769px) {
  .top-margin {
    margin-top: 3.813rem;
  }
}
/*For Mobiles*/
@media only screen and (max-width: 450px) {
  .top-margin {
    margin-top: 1.938rem;
  }
}

.not-for-tab {
  display: flex;
}

/*For tabs */
@media only screen and (max-width: 769px) {
  .not-for-tab {
    display: none;
  }
}

.for-tab-only {
  display: none;
}

/*For tabs */
@media only screen and (max-width: 769px) {
  .for-tab-only {
    display: flex;
  }
}

.flexDisplay {
  display: flex;
}

.marginForDiv {
  margin: 0 2rem;
}

/*For tabs */
@media only screen and (max-width: 769px) {
  .marginForDiv {
    margin: 0 2rem;
  }
}

/*For Mobiles*/
@media only screen and (max-width: 450px) {
  .for-tab-only {
    display: none;
  }
}

.leftDiv {
  float: left;
  width: 25%;
  height: 200px;
}

/*For tabs */
@media only screen and (max-width: 769px) {
  .leftDiv {
    float: left;
    width: 25%;
    height: 280px;
    margin: auto;
  }
}

.middleDiv {
  float: left;
  width: 50%;
  height: 200px;
}

.rightDiv {
  float: right;
  width: 25%;
  height: auto;
}

/* For Desktop */
.free-trial-title {
  font-family: Poppins;
  font-size: 2rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: #4a4a4a;
}
/*For tabs */
@media only screen and (max-width: 769px) {
  .free-trial-title {
    font-family: Poppins;
    font-size: 1.75rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    color: #4a4a4a;
  }
}
/*For Mobiles*/
@media only screen and (max-width: 450px) {
  .free-trial-title {
    font-family: Poppins;
    font-size: 1.25rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    color: #4a4a4a;
  }
}

/* For Desktop */
.create-account-text {
  font-family: Poppins;
  font-size: 1.125rem;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: #555;
}
/*For tabs */
@media only screen and (max-width: 769px) {
  .create-account-text {
    font-family: Poppins;
    font-size: 1rem;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    color: #555;
  }
}
/*For Mobiles*/
@media only screen and (max-width: 450px) {
  .create-account-text {
    font-family: Poppins;
    font-size: 0.875rem;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    color: #555;
  }
}

/* For Desktop */
.agree-container {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  margin-bottom: 0rem;
}
/*For tabs */
@media only screen and (max-width: 769px) {
  .agree-container {
    margin-top: 2rem;
    margin-bottom: 0rem;
  }
}
/*For Mobiles*/
@media only screen and (max-width: 450px) {
  .agree-container {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}

.bottom-margin {
  margin-bottom: 8.375rem;
}

/* For Desktop */
.checkbox {
  width: 0.875rem;
  height: 0.875rem;
  margin: 0;
  object-fit: contain;
  border-radius: 2px;
  border: solid 1px #979797;
  background-color: #ffffff;
}
/*For tabs */
@media only screen and (max-width: 769px) {
  .checkbox {
    width: 0.875rem;
    height: 0.875rem;
    margin: 0.188rem 0.5rem 2rem 0;
    object-fit: contain;
    border-radius: 2px;
    border: solid 1px #979797;
    background-color: #ffffff;
  }
}
/*For Mobiles*/
@media only screen and (max-width: 450px) {
  .checkbox {
    width: 1.25rem;
    height: 1.25rem;
    object-fit: contain;
    margin: 0rem !important;
    border-radius: 2px;
    border: solid 1px #979797;
    background-color: #ffffff;
  }
}

.button-alignment {
  text-align: center;
}

.create-account-button {
  width: 15rem;
  height: 3rem;
  font-family: Poppins;
  font-size: 1.125rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  border-radius: 0.25rem;
  background-color: #2544da;
  border-color: #2544da;
  border-style: hidden;
}

/* For Desktop */
.agree-text {
  margin: 0.2rem 0 0 0.5rem;
  font-family: Poppins;
  font-size: 0.875rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  color: #4a4a4a;
}
/*For tabs */
@media only screen and (max-width: 769px) {
  .agree-text {
    margin: 0.2rem 0 0 0.5rem;
    font-family: Poppins;
    font-size: 0.75rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    color: #4a4a4a;
  }
}
/*For Mobiles*/
@media only screen and (max-width: 450px) {
  .agree-text {
    margin: 0.063rem 0 0 0.375rem;
    font-family: "Open Sans", sans-serif;
    font-size: 0.625rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    color: #4a4a4a;
  }
}

.formalign {
  display: flex;
}

.emptyDiv {
  max-width: 30%;
}
/*For tabs */
@media only screen and (max-width: 769px) {
  .emptyDiv {
    max-width: 50%;
  }
}

.signup-form__title {
  height: 1.75rem;
  margin-top: 1.9375rem;
  margin-bottom: 0.125rem;
  font-family: Poppins;
  font-size: 1.25rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  color: #4a4a4a;
}

.signup-form__subtitle {
  height: 1.75rem;
  /* margin-bottom: 1.875rem; */
  font-family: Poppins;
  font-size: 0.875rem;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: center;
  color: #4a4a4a;
}

.signup-form-free-trial-container {
  width: 100%;
  margin-top: 1.18rem;
  margin-left: 1rem;
}

/*For Mobiles*/
@media only screen and (max-width: 450px) {
  .signup-form-free-trial-container {
    width: auto;
    margin-top: 1.18rem;
    margin-left: 1rem;
  }
  .fts-container {
    display: flex;
    justify-content: center;
  }
}

.signup-form-free-trial-title {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--black);
}

/*For Mobiles*/
@media only screen and (max-width: 450px) {
  .signup-form-free-trial-title {
    height: 2.063rem;
  }
}

.title-section {
  text-align: center;
}

.signup-form-free-trial-statement {
  display: flex;
  margin-top: -1.5rem;
}

.signup-form-free-trial-first-statement {
  margin-top: -0.4rem;
  max-width: 18.25rem;
}

.signup-form-first-tick {
  width: 18px;
  height: 18px;
}

.signup-form-free-trial-second-statement {
  margin-top: 0.1rem;
  max-width: 16.25rem;
}

.signup-form-second-tick {
  width: 18px;
  height: 18px;
}

.signup-form-free-trial-third-statement {
  margin-top: 0.3rem;
  max-width: 18.25rem;
}

.signup-form-free-trial-fourth-statement {
  margin-top: 0.1rem;
  max-width: 16.25rem;
}

.signup-form-fourth-tick {
  width: 18px;
  height: 18px;
}

.signup-form-free-trial-line {
  margin-left: 3%;
  font-family: Poppins;
  font-size: 0.875rem;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  color: #4a4a4a;
}

/*For Mobiles*/
@media only screen and (max-width: 450px) {
  .signup-form-free-trial-line {
    width: 15.813rem;
  }
}

.signup-form__empty-container {
  width: 32.66%;
}

.placeholder-text {
  width: 100%;
  height: 2.25rem;
  margin: 0 auto;
  margin-top: 1.5rem;
  border-radius: 0.125rem;
  border: solid 0.0625rem #bdbabe;
  background-color: #ffffff;
  color: #9b9b9b;
}

.input-value-text {
  padding-left: 0.9375rem;
  width: 100%;
  height: 2.25rem;
  margin: 0 auto;
  margin-top: 1.5rem;
  border-radius: 0.125rem;
  border: solid 0.0625rem #bdbabe;
  background-color: #ffffff;
  color: black;
}

.signup-form__content {
  display: flex;
  width: 100%;
}

.signup-form__field-container {
  width: 93.77%;
  margin-left: 6.23%;
}

.agree {
  display: flex;
  margin: 0.5rem 1rem;
  margin-bottom: 0;
}

.create-account-button:hover {
  background-color: #192f97;
}

#terms_of_svc {
  color: #4a4a4a;
  text-decoration: none;
}

#privacy_policy {
  color: #4a4a4a;
}

.signup-page-error-text {
  margin-top: 0.2rem;
  height: 0.6875rem;
  text-align: right;
  font-family: "Open Sans", sans-serif;
  font-size: 0.6875rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #d0021b;
}

/*For Mobiles*/
@media only screen and (max-width: 450px) {
  .signup-page-error-text {
    text-align: right;
    margin: 0 auto;
    width: 18rem;
  }
}

.checkbox-error-text {
  margin-top: 0.2rem;
  height: 0.6875rem;
  text-align: center;
  font-family: "Open Sans", sans-serif;
  font-size: 0.6875rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #d0021b;
}

/* For Desktop */
.account-exists-section {
  height: 1.563rem;
}
/*For tabs */
.account-exists-section {
  height: 1.563rem;
}
/*For Mobiles*/
.account-exists-section {
  height: 1.563rem;
}

/* For Desktop */
.signup-mobile-view {
  display: none;
}

/*For tabs */
@media only screen and (max-width: 769px) {
  .signup-mobile-view {
    display: none;
  }
}
/*For Mobiles */
@media only screen and (max-width: 450px) {
  .signup-mobile-view {
    display: flex;
  }
}
.non-mobile-device {
  display: flex;
  flex-direction: column;
}
/*For Mobiles*/
@media only screen and (max-width: 450px) {
  .non-mobile-device {
    display: none;
  }
}

.signup-form-no-credit-card {
  margin-top: 0.3125rem;
  font-family: "Open Sans", sans-serif;
  font-size: 0.688rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #4a4a4a;
}

.account-exists-text {
  height: 1.563rem;
  font-family: Poppins;
  font-size: 0.875rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #4a4a4a;
  margin: 0rem 0rem 0rem 0.5rem;
}

.signup-form-signin-link {
  color: #007ee5;
  margin: 0rem 0.5rem;
  text-decoration: underline;
  background-color: white !important;
  border-color: white;
  border-width: 0;
  padding: 0;
  height: 1.125rem;
}

.create-account-button {
  width: 15rem;
  height: 3rem;
  font-family: Poppins;
  font-size: 1.125rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  border-radius: 0.25rem;
  background-color: #2544da;
  border-color: #2544da;
  border-style: hidden;
}

/*For Mobiles*/
@media only screen and (max-width: 450px) {
  .margin {
    margin-top: 2.37rem;
    text-align: center;
  }
}

.margin {
  margin-top: 0rem;
  text-align: center;
}

.form-signup {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-right: 1rem;
}

.input-field-container {
  margin-bottom: -1.5rem;
  margin-left: 0.5rem;
  text-align: end;
}

/*For Mobiles*/
@media only screen and (max-width: 461px) {
  .input-field-container {
    margin-bottom: -1.2rem;
    margin-left: 0.5rem;
    text-align: center;
  }
}

/*For Mobiles*/
@media only screen and (max-width: 450px) {
  .input-field-container {
    margin-bottom: -1.2rem;
    margin-left: -0.5rem;
    text-align: center;
  }
}

/*For Mobiles*/
@media only screen and (max-width: 450px) {
  .placeholder-text {
    width: 18rem;
    height: 2.25rem;
    margin: 0 auto;
    margin-top: 1.5rem;
    border-radius: 0.125rem;
    border: solid 0.0625rem #bdbabe;
    background-color: #ffffff;
    font-family: "Open Sans", sans-serif;
    font-size: 0.875rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: 0.01rem;
    color: #9b9b9b;
  }
}

/*For Mobiles*/
@media only screen and (max-width: 450px) {
  .input-value-text {
    width: 18rem;
    padding-left: 1rem;
    height: 2.25rem;
    margin: 0 auto;
    margin-top: 1.5rem;
    border-radius: 0.125rem;
    border: solid 0.0625rem #bdbabe;
    background-color: #ffffff;
    font-family: "Open Sans", sans-serif;
    font-size: 0.875rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: 0.01rem;
    color: black;
  }
}

.placeholder-text {
  max-width: 18rem;
  height: 2.25rem;
  margin: 0 auto;
  margin-top: 1.5rem;
  border-radius: 0.125rem;
  border: solid 0.0625rem #bdbabe;
  background-color: #ffffff;
  font-family: "Open Sans", sans-serif;
  font-size: 0.875rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 0.01rem;
  color: #9b9b9b;
}

.input-value-text {
  padding-left: 1rem;
  max-width: 18rem;
  height: 2.25rem;
  margin: 0 auto;
  margin-top: 1.5rem;
  border-radius: 0.125rem;
  border: solid 0.0625rem #bdbabe;
  background-color: #ffffff;
  font-family: "Open Sans", sans-serif;
  font-size: 0.875rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 0.01rem;
  color: black;
}

.padded-input {
  padding-left: 0.9375rem;
}

.row-style-signup {
  margin-right: 0rem;
  margin-left: 0rem;
}
