/**
 * /* For mobile
 *
 * @format
 */
.auto-add-img-container {
  height: 6.37rem;
}

.manual-add-img-container {
  height: 6.37rem;
}

@media only screen and (max-width: 550px) {
  .add-url-title {
    height: 1.8125rem;
    padding-top: 0.1875rem;
    margin-top: 1.9375rem;
    font-family: "Poppins", sans-serif;
    font-size: 1.25rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: center;
    color: #4a4a4a;
  }
  .add-url-description-container {
    font-family: "Poppins", sans-serif;
    font-size: 0.875rem;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: center;
    color: #4a4a4a;
  }
  .add-url-description-text {
    width: 15.5rem;
    padding-top: 0.1875rem;
    height: 2.625rem;
    margin: 0 auto;
  }
  .add-url-container {
    display: block;
  }
  .auto-add-url-img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2.125rem;
  }
  .auto-add-url-img-not-loaded {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2.125rem;
  }
  .auto-add-url-button {
    padding-top: 0.1875rem;
    margin-top: 1.25rem;
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: 13.125rem;
    height: 2.25rem;
    border-radius: 0.25rem;
    border: solid 0.0625rem #192f97;
    background-color: #192f97;
    font-family: "Poppins", sans-serif;
    font-size: 0.875rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    border: none;
  }
  .auto-add-help-text {
    margin-top: 0.875rem;
    margin-left: auto;
    margin-right: auto;
    display: block;
    margin-bottom: 0;
    height: 4.4375rem;
    width: 14.125rem;
    font-family: "Poppins", sans-serif;
    font-size: 0.875rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: center;
    color: #4a4a4a;
  }
  .manual-add-url-img {
    margin-top: 0.4375rem;
    margin-left: calc((100% - 100px) * (1 / 2));
    margin-right: calc((100% - 100px) * (1 / 2));
  }
  .manual-add-url-button {
    /* padding-top: 0.1875rem; */
    margin-top: 1.3125rem;
    margin-left: calc((100% - 13.125rem) * (1 / 2));
    margin-right: calc((100% - 13.125rem) * (1 / 2));
    width: 13.125rem;
    height: 2.25rem;
    border-radius: 0.25rem;
    border: solid 0.0625rem #2544da;
    background-color: #2544da;
    font-family: "Poppins", sans-serif;
    font-size: 0.875rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #d8d8d8;
    border: none;
  }
  .manual-add-help-text {
    width: 13.25rem;
    height: 2.625rem;
    margin-left: calc((100% - 13.25rem) * (1 / 2));
    margin-right: calc((100% - 13.25rem) * (1 / 2));
    margin-top: 0.875rem;
    margin-bottom: 2.313rem;
    font-family: "Poppins", sans-serif;
    font-size: 0.875rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: center;
    color: #4a4a4a;
  }
  .separation-div {
    display: none;
  }
}

/* For tablet */
@media only screen and (min-width: 550px) and (max-width: 792px) {
  .add-url-title {
    height: 2.2rem;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3.3125rem;
    font-family: "Poppins", sans-serif;
    font-size: 1.5rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #4a4a4a;
  }
  .add-url-description-container {
    margin-top: 0.25rem;
    height: 1.5rem;
    font-family: "Poppins", sans-serif;
    font-size: 1rem;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    text-align: center;
    color: #555555;
  }
  .add-url-container {
    display: flex;
    margin-top: 2.156rem;
    margin-bottom: 7.156rem;
  }
  .separation-div {
    width: 0.125rem;
    height: 22.5rem;
    background-color: #9b9b9b;
  }
  .auto-add-url-container {
    width: calc((100% - 0.125rem) * (1 / 2));
  }
  .manual-add-url-container {
    width: calc((100% - 0.125rem) * (1 / 2));
  }
  .auto-add-img-container {
    padding-top: 0.719rem;
    display: flex;
    height: 10.37rem;
  }
  .auto-add-left-region {
    width: 57.27%;
  }
  .auto-add-middle-region {
    height: 9.7rem;
    width: 24.25%;
  }
  .auto-add-right-region {
    width: 18.47%;
  }
  .auto-add-url-img {
    width: 100%;
  }
  .auto-add-btn-container {
    padding-top: 2.5rem;
    display: flex;
  }
  .auto-add-btn-left {
    width: 48.4%;
  }
  .auto-add-btn-middle {
    width: 41.94%;
  }
  .auto-add-btn-right {
    width: 9.66%;
  }
  .auto-add-url-button {
    width: 100%;
    height: 2rem;
    border-radius: 0.25rem;
    border-color: #2544da;
    background-color: #2544da;
    font-family: "Poppins", sans-serif;
    font-size: 0.7rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    border: none;
  }
  .auto-add-text-container {
    padding-top: 1.625rem;
    display: flex;
  }
  .auto-add-text-left {
    width: 51.72%;
  }
  .auto-add-text-middle {
    width: 35.31%;
  }
  .auto-add-text-right {
    width: 12.97%;
  }
  .auto-add-help-text {
    width: 100%;
    font-family: "Poppins", sans-serif;
    font-size: 0.65rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: center;
    color: #4a4a4a;
  }
  .manual-add-img-container {
    padding-top: 1.156rem;
    display: flex;
    /* height: 6.37rem; */
    height: 10.37rem;
  }
  .manual-add-left-region {
    width: 19.9%;
  }
  .manual-add-middle-region {
    width: 21.32%;
  }
  .manual-add-right-region {
    width: 58.78%;
  }
  .manual-add-btn-container {
    padding-top: 2.5rem;
    display: flex;
  }
  .manual-add-btn-left {
    width: 8.7%;
  }
  .manual-add-btn-middle {
    width: 44.05%;
  }
  .manual-add-btn-right {
    width: 47.25%;
  }
  .manual-add-url-button {
    width: 100%;
    height: 2rem;
    border-radius: 0.25rem;
    border-color: #2544da;
    background-color: #2544da;
    font-family: "Poppins", sans-serif;
    font-size: 0.7rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    border: none;
  }
  .manual-add-text-container {
    padding-top: 1.625rem;
    display: flex;
  }
  .manual-add-help-text {
    width: 100%;
    font-family: "Poppins", sans-serif;
    font-size: 0.65rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: center;
    color: #4a4a4a;
  }
  .manual-add-text-left {
    width: 13.95%;
  }
  .manual-add-text-middle {
    width: 33.22%;
  }
  .manual-add-text-right {
    width: 52.82%;
  }
}

/* For laptop + desktop */
@media only screen and (min-width: 792px) {
  .add-url-title {
    height: 3rem;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5.3125rem;
    font-family: "Poppins", sans-serif;
    font-size: 2rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #4a4a4a;
  }
  .add-url-description-container {
    margin-top: 0.25rem;
    height: 1.75rem;
    font-family: "Poppins", sans-serif;
    font-size: 1.125rem;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    text-align: center;
    color: #555555;
  }
  .add-url-container {
    display: flex;
    margin-top: 2.156rem;
    margin-bottom: 7.156rem;
  }
  .separation-div {
    width: 0.125rem;
    height: 22.5rem;
    background-color: #9b9b9b;
  }
  .auto-add-url-container {
    width: calc((100% - 0.125rem) * (1 / 2));
  }
  .manual-add-url-container {
    width: calc((100% - 0.125rem) * (1 / 2));
  }
  .auto-add-img-container {
    padding-left: calc((100% - 9.688rem) * (22.938 / 30.313));
    padding-top: 0.719rem;
    height: 10.37rem;
  }
  .auto-add-btn-container {
    padding-left: calc((100% - 14.375rem) * (20.563 / 25.626));
    padding-top: 2.5rem;
  }
  .auto-add-url-button {
    width: 14.375rem;
    height: 3rem;
    border-radius: 0.25rem;
    border-color: #2544da;
    background-color: #2544da;
    font-family: "Poppins", sans-serif;
    font-size: 1rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    border: none;
  }
  .auto-add-text-container {
    padding-left: calc((100% - 14.125rem) * (20.688 / 25.876));
    padding-top: 1.625rem;
  }
  .auto-add-help-text {
    width: 14.125rem;
    font-family: "Poppins", sans-serif;
    font-size: 0.875rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: center;
    color: #4a4a4a;
  }
  .manual-add-img-container {
    padding-top: 1.156rem;
    padding-left: calc((100% - 8.5rem) * (7.938 / 31.376));
    height: 10.37rem;
  }
  .manual-add-btn-container {
    padding-top: 2.9375rem;
    padding-left: calc((100% - 14.375rem) * (5.063 / 25.501));
  }
  .manual-add-url-button {
    width: 14.375rem;
    height: 3rem;
    border-radius: 0.25rem;
    border-color: #2544da;
    background-color: #2544da;
    font-family: "Poppins", sans-serif;
    font-size: 1rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    border: none;
  }
  .manual-add-text-container {
    padding-top: 1.625rem;
    padding-left: calc((100% - 13.25rem) * (5.563 / 26.626));
  }
  .manual-add-help-text {
    width: 13.25rem;
    font-family: "Poppins", sans-serif;
    font-size: 0.875rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: center;
    color: #4a4a4a;
  }
}

.display-none {
  display: none;
}
