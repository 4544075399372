/** @format */

.add-urls-header {
  font-family: "Poppins", sans-serif;
  font-size: 2rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #4a4a4a;
  margin: 5.311rem 0 1.125rem;
}

/*For Tabs and Mobiles*/
@media only screen and (max-width: 767px) {
  .add-urls-header {
    margin: 1.938rem 0 0.063rem 0;
    font-size: 1.25rem;
    font-weight: 600;
  }
}

.add-urls-header2 {
  margin: 0.25rem 0 3.375rem;
  font-family: "Poppins", sans-serif;
  font-size: 1.125rem;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: center;
  color: #555;
}

/*For Tabs and Mobiles*/
@media only screen and (max-width: 767px) {
  .add-urls-header2 {
    margin: 0.063rem 2.375rem 1.875rem 1.438rem;
    font-family: "Poppins", sans-serif;
    font-size: 0.875rem;
  }
}

.header-alignment {
  display: flex;
  height: 1.563rem;
  justify-content: center;
  margin-bottom: -0.5rem;
  object-fit: contain;
}

.div-alignment {
  display: flex;
  justify-content: center;
  height: 16.25rem !important;
}

/*For  Mobiles*/
@media only screen and (max-width: 361px) {
  .div-alignment {
    height: 25rem !important;
  }
}

.copy-paste-header {
  width: 70%;
  font-family: "Poppins", sans-serif;
  font-size: 0.75rem;
  font-weight: 500;
  color: #fff;
  background-color: #a6a3e0;
  height: 1.563rem;
  padding: 0.2rem 0.2rem 0.5rem 1rem;
}

/*For  Mobiles*/
@media only screen and (max-width: 361px) {
  .copy-paste-header {
    width: 80%;
    padding: 0.2rem 0.2rem 0.5rem 1rem;
  }
}

.autocomplete-style {
  width: 70%;
}

/*For  Mobiles*/
@media only screen and (max-width: 361px) {
  .autocomplete-style {
    width: 80%;
  }
}

.text-field-style {
  height: 15.25rem !important;
  margin: 1.375rem 0.063rem 0 !important;
  opacity: 0.52 !important;
  border-radius: 4px !important;
  border: solid 1px #bdbcbe !important;
  background-color: var(--white) !important;
  overflow: auto;
  min-height: 3rem !important;
  display: flex !important;
  flex-wrap: wrap !important;
  /* width: 26.938rem !important; */
  background-color: white !important;
  border-radius: 4px !important;
  padding: 5px !important;
  margin-top: 0.5rem !important;
  padding-top: 7px !important;
  margin-right: 1.5rem;
  margin-bottom: 5rem !important;
}

/*For  Mobiles*/
@media only screen and (max-width: 361px) {
  .text-field-style {
    height: 25rem !important;
  }
}

.save-new-urls-button {
  width: 12.5rem;
  height: 3rem;
  margin: 2.813rem 0 8.125rem;
  border-radius: 4px;
  border-color: transparent;
  background-color: #2544da;
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: white;
}

/*For  Mobiles*/
@media only screen and (max-width: 361px) {
  .save-new-urls-button {
    margin: 2rem 0 8.125rem;
    font-size: 0.875rem;
    height: 2.25rem;
  }
}

.disabled-save-new-urls-button {
  width: 12.5rem;
  height: 3rem;
  margin: 2.813rem 0 8.125rem;
  border-radius: 4px;
  border-color: transparent;
  background-color: #dcddde;
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: white;
}

/*For  Mobiles*/
@media only screen and (max-width: 361px) {
  .disabled-save-new-urls-button {
    margin: 2rem 0 8.125rem;
    font-size: 0.875rem;
    height: 2.25rem;
  }
}

/*For  Mobiles*/
@media only screen and (max-width: 361px) {
  .chip-div-style {
    margin: 0.1rem 0.2rem 0 0;
  }
}

.button-alignment {
  text-align: center;
}
.error-url {
  color: red !important;
}
.error-text {
  margin-top: 2rem;
  height: 0.6875rem;
  margin-bottom: -1rem;
  text-align: center;
  font-family: "Open Sans", sans-serif;
  font-size: 0.8rem;
  color: #d0021b;
}
.chip-div-style {
  margin: 0.1rem;
}

.save-new-urls-button:hover {
  background-color: #192f97;
}
