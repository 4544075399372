@media only screen and (max-width: 450px) {
  .payoff-main {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
  .invalid-name-img-container {
    margin-top: 1rem;
  }
  .all-set-container {
    margin-top: 0.625rem;
    height: 1.75rem;
  }
  .all-set-text {
    padding-top: 0.125rem;
    height: 1.75rem;
    margin-bottom: 0;
    font-family: "Poppins", sans-serif;
    font-size: 1.25rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: center;
    color: #4a4a4a;
  }
  .description-container {
    margin-top: 0.0625rem;
    height: 3.9375rem;
  }
  .description-text-1 {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0;
    width: 17.688rem;
    font-family: "Poppins", sans-serif;
    font-size: 0.875rem;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: center;
    color: #4a4a4a;
  }
  .description-text-2 {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0;
    width: 17.688rem;
    font-family: "Poppins", sans-serif;
    font-size: 0.875rem;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: center;
    color: #4a4a4a;
  }
  .go-to-dashboard-container-mobile {
    margin-top: 1.5rem;
  }
  .go-to-dashboard-container-desktop {
    display: none;
  }
  .go-to-dashboard-btn {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 10rem;
    height: 2.25rem;
    font-family: "Poppins", sans-serif;
    font-size: 0.875rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.04px;
    text-align: center;
    color: #ffffff;
    border-radius: 0.25rem;
    border: solid 0.0625rem #2544da;
    background-color: #2544da;
  }
  .customize-seo-monitoring-container {
    margin-top: 1.5rem;
  }
  .customize-seo-monitoring-text {
    height: 23px;
    font-family: "Poppins", sans-serif;
    font-size: 1rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.1px;
    text-align: center;
    color: #4a4a4a;
    margin-bottom: 0;
  }
  .customization-points-container {
    margin-top: 0.9375rem;
  }
  .customization-point {
    display: flex;
    margin-bottom: 0.5rem;
    padding-left: 0.25rem;
  }
  .customization-group-1 {
    width: 18rem;
    margin-left: calc((100% - 18rem) * (1 / 2));
    margin-right: calc((100% - 18rem) * (1 / 2));
  }
  .customization-group-2 {
    width: 18rem;
    margin-left: calc((100% - 18rem) * (1 / 2));
    margin-right: calc((100% - 18rem) * (1 / 2));
  }
  .customization-group-3 {
    width: 18rem;
    margin-left: calc((100% - 18rem) * (1 / 2));
    margin-right: calc((100% - 18rem) * (1 / 2));
    margin-bottom: 6.75rem;
  }
  .customization-bullet-point {
    padding-left: 2.375rem;
    font-family: "Poppins", sans-serif;
    font-size: 0.75rem;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.92;
    letter-spacing: normal;
    color: #4a4a4a;
  }
  .customization-text {
    margin-bottom: 0;
    margin-left: 0.4375rem;
    font-family: "Poppins", sans-serif;
    font-size: 0.75rem;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    color: #4a4a4a;
    padding-top: 0.1875rem;
  }
  .upgrade-btn {
    border-radius: 4px;
    border: solid 1px #2544da;
    background-color: #ffffff;
    margin-left: 1.8125rem;
    height: 1.125rem;
    width: 4.375rem;
    font-family: "Poppins", sans-serif;
    font-size: 0.625rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #2544da;
  }
  button:hover {
    background-color: #192f97;
  }
}

@media only screen and (min-width: 450px) and (max-width: 650px) {
  .payoff-main {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
  .invalid-name-img-container {
    margin-top: 1.75rem;
  }
  .all-set-container {
    margin-top: 0.625rem;
    height: 2.25rem;
  }
  .all-set-text {
    padding-top: 0.125rem;
    height: 2.25rem;
    margin-bottom: 0;
    font-family: "Poppins", sans-serif;
    font-size: 1.5rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: center;
    color: #4a4a4a;
  }
  .description-container {
    margin-top: 0.0625rem;
    height: 3.9375rem;
  }
  .description-text-1 {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0;
    width: 17.688rem;
    font-family: "Poppins", sans-serif;
    font-size: 1rem;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: center;
    color: #4a4a4a;
  }
  .description-text-2 {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0;
    width: 18.088rem;
    font-family: "Poppins", sans-serif;
    font-size: 1rem;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: center;
    color: #4a4a4a;
  }
  .go-to-dashboard-container-mobile {
    margin-top: 2.25rem;
  }
  .go-to-dashboard-container-desktop {
    display: none;
  }
  .go-to-dashboard-btn {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 12rem;
    height: 2.5rem;
    font-family: "Poppins", sans-serif;
    font-size: 1rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.04px;
    text-align: center;
    color: #ffffff;
    border-radius: 0.25rem;
    border: solid 0.0625rem #2544da;
    background-color: #2544da;
  }
  .customize-seo-monitoring-container {
    margin-top: 2rem;
  }
  .customize-seo-monitoring-text {
    height: 1.75rem;
    font-family: "Poppins", sans-serif;
    font-size: 1.25rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.1px;
    text-align: center;
    color: #4a4a4a;
    margin-bottom: 0;
  }
  .customization-points-container {
    margin-top: 1.125rem;
  }
  .customization-point {
    display: flex;
    margin-bottom: 0.6875rem;
    padding-left: 0.25rem;
  }
  .customization-group-1 {
    width: 18rem;
    margin-left: calc((100% - 18rem) * (1 / 2));
    margin-right: calc((100% - 18rem) * (1 / 2));
  }
  .customization-group-2 {
    width: 18rem;
    margin-left: calc((100% - 18rem) * (1 / 2));
    margin-right: calc((100% - 18rem) * (1 / 2));
  }
  .customization-group-3 {
    width: 18rem;
    margin-left: calc((100% - 18rem) * (1 / 2));
    margin-right: calc((100% - 18rem) * (1 / 2));
    margin-bottom: 6.75rem;
  }
  .customization-bullet-point {
    padding-left: 2.375rem;
    font-family: "Poppins", sans-serif;
    font-size: 0.875rem;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.92;
    letter-spacing: normal;
    color: #4a4a4a;
  }
  .customization-text {
    margin-bottom: 0;
    margin-left: 0.4375rem;
    font-family: "Poppins", sans-serif;
    font-size: 0.875rem;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    color: #4a4a4a;
    padding-top: 0.1875rem;
  }
  .upgrade-btn {
    padding-top: 0.125rem;
    margin-top: -0.125rem;
    border-radius: 4px;
    border: solid 1px #2544da;
    background-color: #ffffff;
    margin-left: 1.8125rem;
    height: 1.25rem;
    width: 4.375rem;
    font-family: "Poppins", sans-serif;
    font-size: 0.625rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #2544da;
  }
  button:hover {
    background-color: #192f97;
  }
}

@media only screen and (min-width: 650px) and (max-width: 870px) {
  .payoff-main {
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: 9rem;
    width: 9rem;
    padding: 0.25rem;
  }
  .invalid-name-img-container {
    margin-top: 2.25rem;
  }
  .all-set-container {
    margin-top: 1.1875rem;
    height: 2.75rem;
  }
  .all-set-text {
    padding-top: 0.125rem;
    height: 1.75rem;
    margin-bottom: 0;
    font-family: "Poppins", sans-serif;
    font-size: 1.5rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #4a4a4a;
  }
  .description-container {
    margin-top: 0.0625rem;
    height: 2.4375rem;
    display: flex;
  }
  .description-left {
    width: calc((100% - 36.5625rem) * (1 / 2));
  }
  .description-middle {
    width: 36.5625rem;
    display: flex;
  }
  .description-right {
    width: calc((100% - 36.5625rem) * (1 / 2));
  }
  .description-text-1 {
    margin-bottom: 0;
    font-family: "Poppins", sans-serif;
    font-size: 0.875rem;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: normal;
    text-align: center;
    color: #555;
  }
  .description-text-2 {
    margin-bottom: 0;
    margin-left: 0.5rem;
    font-family: "Poppins", sans-serif;
    font-size: 0.875rem;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: normal;
    text-align: center;
    color: #555;
  }
  .go-to-dashboard-container-mobile {
    display: none;
  }
  .go-to-dashboard-container-desktop {
    margin-top: 1.9375rem;
    margin-bottom: 8.125rem;
  }
  .go-to-dashboard-btn {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 11rem;
    height: 2.5rem;
    font-family: "Poppins", sans-serif;
    font-size: 0.875rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    border-radius: 0.25rem;
    border: solid 0.0625rem #2544da;
    background-color: #2544da;
  }
  .customize-seo-monitoring-container {
    margin-top: 0.5rem;
    height: 2.1875rem;
  }
  .customize-seo-monitoring-text {
    font-family: "Poppins", sans-serif;
    font-size: 1.125rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #4a4a4a;
    margin-bottom: 0;
  }
  .customization-points-container {
    height: 8.75rem;
    margin-top: 0.375rem;
    display: flex;
  }
  .customization-point {
    display: flex;
    padding-left: 0.25rem;
  }
  .customization-point-1 {
    margin-bottom: 0.625rem;
  }
  .customization-group-1 {
    width: 13rem;
    margin-left: calc((100% - 39rem) * (1 / 2));
  }
  .customization-group-2 {
    width: 11rem;
  }
  .customization-group-3 {
    width: 15rem;
    margin-right: calc((100% - 39rem) * (1 / 2));
    margin-bottom: 6.75rem;
  }
  .customization-bullet-point {
    margin-bottom: 0.6875rem;
    padding-left: 2.375rem;
    font-family: "Poppins", sans-serif;
    font-size: 0.75rem;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.79;
    letter-spacing: normal;
    color: #4a4a4a;
  }
  .tick-mark-1 {
    padding-top: 0.5625rem;
  }
  .tick-mark-2 {
    padding-top: 0.5625rem;
  }
  .tick-mark-3 {
    padding-top: 0.5625rem;
  }
  .customization-point-2 {
    margin-top: 0.0625rem;
  }
  .customization-point-3 {
    margin-top: 0.875rem;
  }
  .customization-point-4 {
    margin-top: 0.375rem;
  }
  .customization-point-5 {
    height: 2rem;
    margin-bottom: 0.1875rem;
  }
  .customization-point-6 {
    height: 2rem;
    margin-bottom: 0.1875rem;
  }
  .customization-text {
    margin-bottom: 0;
    margin-left: 0.5rem;
    font-family: "Poppins", sans-serif;
    font-size: 0.75rem;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.13;
    letter-spacing: normal;
    color: #4a4a4a;
    padding-top: 0.3125rem;
  }
  .customization-text-1 {
    height: 1.9375rem;
  }
  .customization-text-2 {
    line-height: 1;
  }
  .customization-text-3 {
    line-height: 1;
  }
  .customization-text-4 {
    line-height: 1;
  }
  .upgrade-btn {
    margin-top: -0.25rem;
    border-radius: 4px;
    border: solid 1px #2544da;
    background-color: #ffffff;
    margin-left: 1.8125rem;
    height: 1.125rem;
    width: 4.375rem;
    font-family: "Poppins", sans-serif;
    font-size: 0.5675rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #2544da;
    border-top-width: 0.125rem;
    border-bottom-width: 0.125rem;
  }
  button:hover {
    background-color: #192f97;
  }
}

@media only screen and (min-width: 870px) {
  .payoff-main {
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: 10.625rem;
    width: 10.625rem;
    padding: 0.25rem;
  }
  .invalid-name-img-container {
    margin-top: 3.375rem;
  }
  .all-set-container {
    margin-top: 1.1875rem;
    height: 3.6875rem;
  }
  .all-set-text {
    padding-top: 0.125rem;
    height: 1.75rem;
    margin-bottom: 0;
    font-family: "Poppins", sans-serif;
    font-size: 2rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #4a4a4a;
  }
  .description-container {
    margin-top: 0.0625rem;
    height: 2.4375rem;
    display: flex;
  }
  .description-left {
    width: calc((100% - 46.8125rem) * (1 / 2));
  }
  .description-middle {
    width: 46.8125rem;
    display: flex;
  }
  .description-right {
    width: calc((100% - 46.8125rem) * (1 / 2));
  }
  .description-text-1 {
    margin-bottom: 0;
    font-family: "Poppins", sans-serif;
    font-size: 1.125rem;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: normal;
    text-align: center;
    color: #555;
  }
  .description-text-2 {
    margin-bottom: 0;
    margin-left: 0.5rem;
    font-family: "Poppins", sans-serif;
    font-size: 1.125rem;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: normal;
    text-align: center;
    color: #555;
  }
  .go-to-dashboard-container-mobile {
    display: none;
  }
  .go-to-dashboard-container-desktop {
    margin-top: 1.9375rem;
    margin-bottom: 8.125rem;
  }
  .go-to-dashboard-btn {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 12.5rem;
    height: 3rem;
    font-family: "Poppins", sans-serif;
    font-size: 1rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    border-radius: 0.25rem;
    border: solid 0.0625rem #2544da;
    background-color: #2544da;
  }
  .customize-seo-monitoring-container {
    margin-top: 1.3125rem;
    height: 2.1875rem;
  }
  .customize-seo-monitoring-text {
    font-family: "Poppins", sans-serif;
    font-size: 1.25rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #4a4a4a;
    margin-bottom: 0;
  }
  .customization-points-container {
    height: 8.75rem;
    margin-top: 0.375rem;
    display: flex;
  }
  .customization-point {
    display: flex;
    padding-left: 0.25rem;
  }
  .customization-point-1 {
    margin-bottom: 0.625rem;
  }
  .customization-group-1 {
    width: 17.25rem;
    margin-left: calc((100% - 51.75rem) * (1 / 2));
  }
  .customization-group-2 {
    width: 17.25rem;
    /* margin-left: calc((100% - 18rem) * (1 / 2));
    margin-right: calc((100% - 18rem) * (1 / 2)); */
  }
  .customization-group-3 {
    width: 17.25rem;
    margin-right: calc((100% - 51.75rem) * (1 / 2));
    margin-bottom: 6.75rem;
  }
  .customization-bullet-point {
    margin-bottom: 0.6875rem;
    padding-left: 2.375rem;
    font-family: "Poppins", sans-serif;
    font-size: 0.875rem;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.79;
    letter-spacing: normal;
    color: #4a4a4a;
  }
  .tick-mark-1 {
    padding-top: 0.5625rem;
  }
  .tick-mark-2 {
    padding-top: 0.5625rem;
  }
  .tick-mark-3 {
    padding-top: 0.5625rem;
  }
  .customization-point-2 {
    margin-top: 0.0625rem;
  }
  .customization-point-3 {
    margin-top: 0.875rem;
  }
  .customization-point-4 {
    margin-top: 0.375rem;
  }
  .customization-point-5 {
    height: 2.25rem;
    margin-bottom: 0.1875rem;
  }
  .customization-point-6 {
    height: 2.25rem;
    margin-bottom: 0.1875rem;
  }
  .customization-text {
    margin-bottom: 0;
    margin-left: 0.5rem;
    font-family: "Poppins", sans-serif;
    font-size: 1rem;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.13;
    letter-spacing: normal;
    color: #4a4a4a;
    padding-top: 0.1875rem;
  }
  .customization-text-1 {
    height: 1.9375rem;
  }
  .customization-text-2 {
    line-height: 1;
  }
  .customization-text-3 {
    line-height: 1;
  }
  .customization-text-4 {
    line-height: 1;
  }
  .upgrade-btn {
    margin-top: -0.25rem;
    border-radius: 4px;
    border: solid 1px #2544da;
    background-color: #ffffff;
    margin-left: 1.8125rem;
    height: 1.125rem;
    width: 4.375rem;
    font-family: "Poppins", sans-serif;
    font-size: 0.5675rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #2544da;
    border-top-width: 0.125rem;
    border-bottom-width: 0.125rem;
  }
  button:hover {
    background-color: #192f97;
  }
}

.upgrade-btn:hover {
  background-color: #2544da;
  color: #ffffff;
}
