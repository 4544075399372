@media only screen and (max-width: 450px) {
  .reset-password-title-container {
    height: 1.75rem;
    margin-top: 1.9375rem;
  }
  .reset-password-title-text {
    font-family: "Poppins", sans-serif;
    font-size: 1.25rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: center;
    color: #4a4a4a;
    margin-bottom: 0;
  }
  .reset-password-description-container {
    margin-top: 0.0625rem;
  }
  .reset-password-description-text {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0;
    font-family: "Poppins", sans-serif;
    font-size: 0.875rem;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: center;
    color: #4a4a4a;
    width: 17.6875rem;
    height: 3.9375rem;
  }
  .form-container {
    margin-top: 2.375rem;
  }
  .email-field-rp {
    width: 80.56%;
    margin-left: 9.72%;
    margin-right: 9.72%;
    padding-left: 0.9375rem;
    height: 2.25rem;
    border-radius: 0.125rem;
    border: solid 0.0625rem #bdbabe;
    background-color: #ffffff;
  }
  ::placeholder {
    /* font-family: "Open Sans", sans-serif; */
    font-size: 0.875rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: 0.16px;
    color: #9b9b9b;
  }
  input {
    outline: none;
  }
  /* input:focus {
    border: solid 0.0625rem #2544da;
  } */
  .input-with-error {
    border: solid 0.0625rem #d0021b;
  }
  .error-text {
    margin-top: 0.2rem;
    height: 0.6875rem;
    margin-bottom: 1.7375rem;
    text-align: center;
    font-family: "Open Sans", sans-serif;
    font-size: 0.6875rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #d0021b;
  }
  .button-container-rp {
    padding-left: calc((100% - 16.5rem) * (1 / 2));
    padding-right: calc((100% - 16.5rem) * (1 / 2));
    margin-bottom: 22.6875rem;
  }
  .cancel-button {
    width: 5.25rem;
    height: 2.25rem;
    border-radius: 0.25rem;
    border: solid 0.0625rem #2544da;
    background-color: #ffffff;
    font-family: "Poppins", sans-serif;
    font-size: 0.875rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.04px;
    text-align: center;
    color: #2544da;
  }
  .reset-password-button {
    width: 10rem;
    height: 2.25rem;
    margin-left: 1.25rem;
    border-radius: 0.25rem;
    border: solid 0.0625rem #2544da;
    background-color: #2544da;
    font-family: "Poppins", sans-serif;
    font-size: 0.875rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.04px;
    text-align: center;
    color: #ffffff;
  }
}

@media only screen and (min-width: 450px) and (max-width: 792px) {
  .reset-password-title-container {
    height: 2.25rem;
    margin-top: 3.5rem;
  }
  .reset-password-title-text {
    font-family: "Poppins", sans-serif;
    font-size: 1.5rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #4a4a4a;
  }
  .reset-password-description-container {
    margin-top: 0.25rem;
  }
  .reset-password-description-text {
    width: 24rem;
    display: block;
    margin-left: auto;
    margin-right: auto;
    font-family: "Poppins", sans-serif;
    font-size: 1rem;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    text-align: center;
    color: #555;
    height: 3.5rem;
  }
  .form-container {
    margin-top: 1.8rem;
  }
  .email-field-rp {
    width: 21.875rem;
    margin-left: calc((100% - 21.875rem) * (1 / 2));
    margin-right: calc((100% - 21.875rem) * (1 / 2));
    padding-left: 0.9375rem;
    height: 2.25rem;
    border-radius: 0.125rem;
    border: solid 0.0625rem #bdbabe;
    background-color: #ffffff;
  }
  ::placeholder {
    /* font-family: "Open Sans", sans-serif; */
    font-size: 0.875rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: 0.01rem;
    color: #9b9b9b;
  }
  input {
    outline: none;
  }
  /* input:focus {
    border: solid 0.0625rem #2544da;
  } */
  .input-with-error {
    border: solid 0.0625rem #d0021b;
  }
  .error-text {
    margin-top: 0.2rem;
    height: 0.6875rem;
    margin-bottom: 2rem;
    text-align: center;
    font-family: "Open Sans", sans-serif;
    font-size: 0.6875rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #d0021b;
  }
  .button-container-rp {
    padding-left: calc((100% - 23.25rem) * (1 / 2));
    padding-right: calc((100% - 23.25rem) * (1 / 2));
    margin-bottom: 12rem;
    display: flex;
  }
  .cancel-button {
    width: 11rem;
    height: 2.5rem;
    border-radius: 0.25rem;
    border: solid 0.0625rem #2544da;
    background-color: #ffffff;
    font-family: "Poppins", sans-serif;
    font-size: 1rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #2544da;
  }
  .reset-password-button {
    width: 11rem;
    height: 2.5rem;
    margin-left: 1.25rem;
    border-radius: 0.25rem;
    border: solid 0.0625rem #2544da;
    background-color: #2544da;
    font-family: "Poppins", sans-serif;
    font-size: 1rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
  }
}

@media only screen and (min-width: 792px) {
  .reset-password-title-container {
    height: 3rem;
    margin-top: 5.3125rem;
  }
  .reset-password-title-text {
    font-family: "Poppins", sans-serif;
    font-size: 2rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #4a4a4a;
  }
  .reset-password-description-container {
    margin-top: 0.25rem;
  }
  .reset-password-description-text {
    display: block;
    margin-left: auto;
    margin-right: auto;
    font-family: "Poppins", sans-serif;
    font-size: 1.125rem;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    text-align: center;
    color: #555;
    width: 31.625rem;
    height: 3.5rem;
  }
  .form-container {
    margin-top: 2.4375rem;
  }
  .email-field-rp {
    width: 25rem;
    margin-left: calc((100% - 25rem) * (1 / 2));
    margin-right: calc((100% - 25rem) * (1 / 2));
    padding-left: 0.9375rem;
    height: 2.25rem;
    border-radius: 0.125rem;
    border: solid 0.0625rem #bdbabe;
    background-color: #ffffff;
  }
  ::placeholder {
    /* font-family: "Open Sans", sans-serif; */
    font-size: 0.875rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: 0.01rem;
    color: #9b9b9b;
  }
  input {
    outline: none;
  }
  /* input:focus {
    border: solid 0.0625rem #2544da;
  } */
  .input-with-error {
    border: solid 0.0625rem #d0021b;
  }
  .error-text {
    margin-top: 0.2rem;
    height: 0.6875rem;
    margin-bottom: 2.3rem;
    text-align: center;
    font-family: "Open Sans", sans-serif;
    font-size: 0.6875rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #d0021b;
  }
  .button-container-rp {
    padding-left: calc((100% - 28.75rem) * (1 / 2));
    padding-right: calc((100% - 28.75rem) * (1 / 2));
    margin-bottom: 13.875rem;
    display: flex;
  }
  .cancel-button {
    width: 13.75rem;
    height: 3rem;
    border-radius: 0.25rem;
    border: solid 0.0625rem #2544da;
    background-color: #ffffff;
    font-family: "Poppins", sans-serif;
    font-size: 1.125rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #2544da;
  }
  .reset-password-button {
    width: 13.75rem;
    height: 3rem;
    margin-left: 1.25rem;
    border-radius: 0.25rem;
    border: solid 0.0625rem #2544da;
    background-color: #2544da;
    font-family: "Poppins", sans-serif;
    font-size: 1.125rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
  }
}
