.footer {
  width: 100%;
  background-color: #dcddde;
}

/* For mobiles */
@media only screen and (max-width: 360px) {
  .footer {
    height: 5rem;
  }
}

/* For tabs */
@media only screen and (max-width: 768px) and (min-width: 361px) {
  .footer {
    height: 8.75rem;
  }  
}

/* For Desktop */
@media only screen and (min-width: 769px) {
  .footer {
    height: 10rem;
  }
}